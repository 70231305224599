import type React from 'react';
import { useDynamicBooleanFlag } from '@eucalyptusvc/react-ff-client';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'shared/components/ds-components/Typography';

export const MaintenanceModeWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const isOffline = useDynamicBooleanFlag('doctors-ui-maintenance-offline');

  if (isOffline) {
    return (
      <section className="h-screen flex">
        <div className="max-w-lg mx-auto my-auto space-y-8 p-5">
          <div className="space-y-4">
            <Typography
              variant="paragraph"
              className="text-center font-semibold"
            >
              <FormattedMessage
                defaultMessage="Sorry, we’re down for maintenance"
                description="Maintenance mode screen heading"
              />
            </Typography>
            <Typography variant="paragraph" className="text-center">
              <FormattedMessage
                defaultMessage="There is an issue with our system, and we are trying to fix it. {br} Please refer to #help-engineering for status updates"
                description="Maintenance mode screen description"
                values={{ br: <br /> }}
              />
            </Typography>
          </div>
        </div>
      </section>
    );
  }

  return <>{children}</>;
};
