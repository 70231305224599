import { useCallback, useEffect, useRef, useState } from 'react';

const valueRequest = 'value';
const clearRequest = 'clear';

/**
 * Stores a string value in session storage.
 *
 * If the value doesn't exist in the current contexts session storage
 * it will be requested from any active siblings via {@link https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API}.
 *
 * If no response is received by the timeout, the value will be null.
 *
 * @param storageKey camel-case string to use as the session storage item key
 */
export function useSharedTabStorage(
  storageKey: string,
  { timeout = 150 }: { timeout?: number } = {},
): {
  value: string | null;
  setValue: (value: string | null) => void;
  clearValue: () => void;
  loading: boolean;
} {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [value, _setValue] = useState(sessionStorage.getItem(storageKey));
  const requestChannelRef = useRef(
    new BroadcastChannel(`request-${storageKey}`),
  );
  const receiveChannelRef = useRef(
    new BroadcastChannel(`receive-${storageKey}`),
  );
  const [loading, setLoading] = useState(!value);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const setValue = useCallback(
    (v: string | null) => {
      _setValue(v);
      if (v) {
        sessionStorage.setItem(storageKey, v);
      } else {
        sessionStorage.removeItem(storageKey);
      }
    },
    [storageKey],
  );

  const clearValue = useCallback(() => {
    setValue(null);
    requestChannelRef.current.postMessage(clearRequest);
  }, [setValue]);

  useEffect(() => {
    requestChannelRef.current.onmessage = (msg: MessageEvent): void => {
      if (msg.data === valueRequest) {
        if (value) receiveChannelRef.current.postMessage(value);
      } else if (msg.data === clearRequest) {
        setValue(null);
      }
    };
  }, [setValue, value]);

  useEffect(() => {
    receiveChannelRef.current.onmessage = (msg: MessageEvent): void => {
      if (msg.data && msg.data !== value) {
        setValue(msg.data);
        setLoading(false);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      }
    };
  }, [setValue, storageKey, value]);

  useEffect(() => {
    if (loading) {
      requestChannelRef.current.postMessage(valueRequest);
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
      }, timeout);
    }
    // just run once, don't care about changes to loading/timeout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { value, setValue, clearValue, loading };
}
