import type React from 'react';
import { createContext, useState, useEffect } from 'react';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { useNotifications } from 'shared/components/notifications';
import { InactiveTimer } from 'inactive-timer';
import { getConfig } from 'config';
import { useIntl } from 'react-intl';
import { useAuth } from './auth';

type InactivityTimeoutContextType = {
  timer: InactiveTimer | null;
};

export const InactivityTimeoutContext =
  createContext<InactivityTimeoutContextType>({
    timer: null,
  });

export const InactivityTimeoutManager: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const { enableLogoutOnInactivity } = getConfig();
  const { loggedIn: isLoggedIn, logout } = useAuth();
  const showNotifications = useNotifications();
  const featureFlagClient = useFeatureFlagClient();
  const [timer, setTimer] = useState<InactiveTimer | null>(null);

  useEffect(() => {
    const inactiveTimeoutFlag = featureFlagClient.getNumber(
      'doctors_inactive_timeout_s',
    );

    let inactiveTimeout: number;
    if (inactiveTimeoutFlag && Number(inactiveTimeoutFlag) > 0) {
      inactiveTimeout = inactiveTimeoutFlag;
    } else {
      inactiveTimeout = 10 * 60;
    }

    const inactivityTimer = new InactiveTimer({
      timeoutSeconds: inactiveTimeout,
    });

    inactivityTimer.setOnExpire(() => {
      void logout();
      showNotifications({
        type: 'warning',
        message: formatMessage({
          defaultMessage: 'You’ve been logged out due to inactivity',
        }),
      });
    });

    setTimer(inactivityTimer);

    return () => {
      inactivityTimer.cleanUp();
    };
  }, [featureFlagClient, logout, showNotifications, formatMessage]);

  useEffect(() => {
    if (!timer) {
      return;
    }

    if (enableLogoutOnInactivity) {
      if (isLoggedIn) {
        timer.start();
      } else {
        timer.cleanUp();
      }
    }
  }, [timer, isLoggedIn, enableLogoutOnInactivity]);

  return (
    <InactivityTimeoutContext.Provider
      value={{
        timer,
      }}
    >
      {children}
    </InactivityTimeoutContext.Provider>
  );
};
