import type { SharedEnvironment } from '../types';
import { defaultEnv } from './default';

export const sharedDevEnv: SharedEnvironment = {
  ...defaultEnv,
  environment: 'development',
  googleOAuthClientId:
    '966685249037-i9ovbcghtjb54injncfejmn3glsgb6ig.apps.googleusercontent.com',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee4',
  googlePlacesApiKey: 'AIzaSyAGJCtA0e32LVJnc7bL2IQdrHfsf5ALhqU',
};
