import type { UserRole } from 'graphql/types';
import { useIntl } from 'react-intl';

export const routes = {
  consultations: '/consultations',
  patients: '/patients',
  outreach: '/outreach',
  login: '/login',
  whoAreYou: '/who-are-you',
  root: '/',
};

interface HomeRoute {
  path: string;
  title: string;
  search?: string;
}

export function useHomeRoute({ role }: { role?: UserRole | null }): HomeRoute {
  const { formatMessage } = useIntl();
  if (!role) {
    return { path: routes.whoAreYou, title: 'Who are you' };
  }

  const homeRoutes: Partial<Record<UserRole, HomeRoute>> = {
    HEALTH_COACH: {
      path: routes.root,
      title: formatMessage({
        defaultMessage: 'Home',
        description: 'Navigation title for the Home screen',
      }),
    },
    DOCTOR: {
      path: routes.consultations,
      title: formatMessage({
        defaultMessage: 'Consultations',
        description: 'Navigation title for the Consultations screen',
      }),
      search: `?statuses=DOCTOR_ASSIGNED`,
    },
  };

  const route = homeRoutes[role];

  if (!route) {
    throw new Error(`role ${role} not supported`);
  }

  return route;
}

export function getCurrentParamsForRedirect(): string {
  const { pathname, search, hash } = window.location;
  const target = `${pathname}${search}${hash}`;

  return `?redirect=${encodeURIComponent(target)}`;
}
