import type React from 'react';
import { env } from 'env';
import { IntlProvider } from 'react-intl';
import type { Logger } from 'shared/logging';
import { useMessages } from './useMessages';
import { getCorrectLocale, getErrorHandler } from './utils';

interface I18nProviderProps {
  // Optional, will fallback to default strings for AU brands
  getTranslationFile?: (
    locale: string,
  ) => Promise<Record<string, unknown>> | undefined;
  logger?: Logger;
  children: React.ReactNode;
}

export const I18nProvider: React.FC<I18nProviderProps> = ({
  children,
  getTranslationFile,
  logger,
}) => {
  const locale = getCorrectLocale(env);
  const { onError } = getErrorHandler();

  const { loading, messages } = useMessages(locale, getTranslationFile, logger);

  if (loading) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
};
