import type {
  ProblemType,
  Address,
  Maybe,
  PregnancyStatus,
  HealthcareIdentifierMedicare,
} from 'graphql/types';
import { format, isValid, differenceInMonths } from 'date-fns';
import { getConfig } from 'config';

export const sleep = async (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export type Colors =
  | 'primary'
  | 'gray'
  | 'red'
  | 'slate'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'amber';

type Shades =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export type BackgroundColors =
  | 'bg-primary'
  | `bg-${Exclude<Colors, 'primary'>}-${Shades}`;

export const getProblemTypeFromString = (
  str?: string | null,
): undefined | ProblemType => {
  switch (str) {
    case 'HAIR':
    case 'ERECTILE':
    case 'PREMATURE':
    case 'HERPES':
    case 'COLD_SORES':
    case 'SLEEP':
    case 'ACNE':
    case 'ANTI_AGEING':
    case 'SKIN_GENERAL':
    case 'CONTRACEPTION':
    case 'FERTILITY':
    case 'FERTILITY_HORMONE':
    case 'PRECONCEPTION_FERTILITY':
    case 'PRECONCEPTION_NUTRITION':
    case 'PRECONCEPTION_MENTAL_HEALTH':
    case 'PRECONCEPTION_PHYSIO':
    case 'PREGNANCY':
    case 'PREGNANCY_NUTRITION':
    case 'PREGNANCY_MENTAL_HEALTH':
    case 'PREGNANCY_PHYSIO':
    case 'POSTPARTUM':
    case 'POSTPARTUM_NUTRITION':
    case 'POSTPARTUM_MENTAL_HEALTH':
    case 'POSTPARTUM_PHYSIO':
    case 'BREASTFEEDING':
    case 'INFANT_SLEEP':
    case 'STI':
    case 'WEIGHT_LOSS':
    case 'MENOPAUSE':
      return str;
    default:
      return undefined;
  }
};

export const getPregnancyStatusString = (
  pregnancyStatus: PregnancyStatus,
): string => {
  switch (pregnancyStatus) {
    case 'PREGNANT':
      return 'Pregnant';
    case 'BREASTFEEDING':
      return 'Breastfeeding';
    case 'POTENTIALLY_PREGNANT':
      return 'Potentially pregnant';
    case 'TRYING_FOR_BABY':
      return 'Trying for a baby';
    case 'NOT_PREGNANT':
      return 'Not pregnant';
    case 'NOT_APPLICABLE':
      return 'Not applicable';
    default:
      return 'Unknown';
  }
};

export const formatProblemType = (type: ProblemType): string => {
  const removeUnderscoreRegex = /_/g;
  return type
    .toString()
    .toLocaleLowerCase()
    .replace(removeUnderscoreRegex, ' ');
};

export const formatDob = (
  input: Date | string | number,
  showAge = false,
): string => {
  const config = getConfig();

  let date;
  if (typeof input === 'string' && input.length === 10) {
    date = new Date(`${input}T00:00:00.000`);
  } else {
    date = new Date(input);
  }

  if (!isValid(date)) {
    return 'Invalid Date';
  }

  let dobFormatted = format(date, config.dateOfBirthFormat);

  if (showAge) {
    const ageInMonths = differenceInMonths(new Date(), date);
    const ageInYears = Math.floor(ageInMonths / 12);
    const ageFormatted = `${ageInYears}y ${ageInMonths % 12}m`;
    dobFormatted = `${dobFormatted} (${ageFormatted})`;
  }

  return dobFormatted;
};

const capitaliseString = (string: string): string =>
  string.slice(0, 1).toUpperCase() + string.slice(1);

export const upperSnakeCaseToCapitalCase = (s: string): string =>
  s
    .split('_')
    .map((st) => capitaliseString(st.toLowerCase()))
    .join(' ');

export const getSingleLineAddress = (address: Maybe<Address>): string => {
  if (!address) {
    return '';
  }
  return `${address.line1}${address.line2 ? `, ${address.line2}` : ''} ${
    address.city
  } ${address.state ? `${address.state} ` : ''}${address.postalCode}`;
};

export function mustBe<TValue>(value: Maybe<TValue>): value is TValue {
  return value !== null && value !== undefined;
}

export const formatMedicare = (
  healthcareIdentifierMedicare: HealthcareIdentifierMedicare,
): string => {
  const { medicareNumber, medicareIrn, validToMonth, validToYear } =
    healthcareIdentifierMedicare;
  const validToDate = new Date(validToYear, validToMonth - 1);
  const validToFormatted = format(validToDate, 'MM/yyyy');
  return `${medicareNumber} (${medicareIrn}) (${validToFormatted})`;
};
