import React from 'react';

type Props = {
  name: string;
  checked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const BaseToggle = (
  { name, checked, onChange }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => (
  <label className="toggle">
    <input
      ref={ref}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <span className="slider" />
  </label>
);

export const Toggle = React.forwardRef<HTMLInputElement, Props>(BaseToggle);
