import type React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import type { UserRole } from 'graphql/types';
import {
  getCurrentParamsForRedirect,
  routes,
  useHomeRoute,
} from './shared/routes';
import { NavBarLayout } from './navbar-layout';
import { useAuth } from './auth';

interface PrivateRouteProps extends RouteProps {
  path: string | undefined;
  allowedRoles: UserRole[];
}

export function PrivateRoute({
  children,
  allowedRoles,
  ...rest
}: PrivateRouteProps): React.ReactElement {
  const { loggedIn, role } = useAuth();
  const homeRoute = useHomeRoute({ role }).path;

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: routes.login,
          search: getCurrentParamsForRedirect(),
        }}
      />
    );
  }

  if (!role) {
    return (
      <Redirect
        to={{
          pathname: routes.whoAreYou,
        }}
      />
    );
  }

  if (!allowedRoles.includes(role)) {
    return (
      <Redirect
        to={{
          pathname: homeRoute,
        }}
      />
    );
  }

  return (
    <Route {...rest}>
      <NavBarLayout>{children}</NavBarLayout>
    </Route>
  );
}
