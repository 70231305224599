import { env } from 'env';
import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import type { ApolloError } from '@apollo/client';

const service = `${env.brand}-doctors-ui-${env.environment}`;

datadogLogs.init({
  clientToken: env.datadogToken,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: env.environment,
  service,
  version: import.meta.env.VITE_RELEASE_VERSION,
});

export const { logger } = datadogLogs;

logger.setHandler(
  env.environment === 'production' ? HandlerType.http : HandlerType.console,
);

export const onError = (e: ApolloError): void => {
  if (env.environment !== 'production') {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export type Logger = typeof logger;
