const translationFiles = import.meta.glob('./*.json', { as: 'raw' });

export const getTranslationFile = (
  locale: string,
): Promise<Record<string, unknown>> | undefined => {
  // Japanese
  if (locale.toLowerCase().match('ja')) {
    return translationFiles['./ja.json']?.().then(
      (json) => JSON.parse(json) as Record<string, unknown>,
    );
  }
  return undefined;
};
