import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

type ChangeUrlParams = {
  url?: string;
  params: Record<string, unknown | unknown[]>;
};

function paramsToObject(query: URLSearchParams): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  query.forEach((value, key) => {
    if (result[key]) {
      if (Array.isArray(result[key])) {
        (result[key] as string[]).push(value);
      } else {
        result[key] = [result[key] as string, value];
      }
    } else {
      result[key] = value;
    }
  });
  return result;
}

export function useChangeUrl(): (params: ChangeUrlParams) => void {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const query = useLocation().search;

  return useCallback(
    ({ url = path, params }: ChangeUrlParams): void => {
      const oldUrl = buildUrl(path, {
        ...paramsToObject(new URLSearchParams(query)),
      });
      const newUrl = buildUrl(url, {
        ...paramsToObject(new URLSearchParams(query)),
        ...params,
      });
      if (oldUrl !== newUrl) {
        history.push(newUrl);
      }
    },
    [history, path, query],
  );
}

function buildUrl(baseUrl: string, params: Record<string, unknown>): string {
  const url = new URL(baseUrl, window.location.origin);

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((v) => url.searchParams.append(key, String(v)));
    } else if (value !== undefined) {
      url.searchParams.set(key, String(value));
    }
  });

  return url.pathname + url.search;
}
