import { GoogleOAuthProvider } from '@react-oauth/google';
import { env } from 'env';
import { GlobalLoader } from 'global-loader';
import type React from 'react';
import { useState } from 'react';

export const GoogleAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <GlobalLoader></GlobalLoader>;
  }

  return (
    <GoogleOAuthProvider
      clientId={env.googleOAuthClientId}
      onScriptLoadSuccess={(): void => setLoading(false)}
    >
      {children}
    </GoogleOAuthProvider>
  );
};
