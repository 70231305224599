import type React from 'react';
import { useContext, createContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import type { IdentityProviderQuery } from 'graphql/types';
import { useAuth } from '../auth';

export type Identity = IdentityProviderQuery['profile'];

const IdentityContext = createContext<Identity>(undefined);

export function IdentityProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const { loggedIn, setIdentity } = useAuth();
  const resp = useQuery<IdentityProviderQuery>(
    gql`
      query IdentityProvider {
        profile {
          id
          role
          email
          fullName
          fullClinicianName
          avatar {
            id
            url
          }
          provider {
            id
            clinicianType
          }
        }
      }
    `,
    { skip: !loggedIn },
  );

  useEffect(() => {
    if (!resp.data?.profile) {
      return;
    }
    setIdentity(resp.data.profile);
  }, [setIdentity, resp.data?.profile]);

  return (
    <IdentityContext.Provider value={resp.data?.profile}>
      {props.children}
    </IdentityContext.Provider>
  );
}

export const useIdentity = (): Identity => useContext(IdentityContext);
