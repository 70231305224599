import { datadogRum } from '@datadog/browser-rum';
import { env } from 'env';
import type { Maybe, UserRole } from 'graphql/types';

const service = `${env.brand}-doctors-ui-${env.environment}`;

datadogRum.init({
  applicationId: env.datadogRumApplicationId,
  clientToken: env.datadogRumToken,
  site: 'datadoghq.com',
  service,
  env: env.environment,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0, // Disable session replays
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask',
  allowedTracingUrls: [env.apiUrl],
  version: import.meta.env.RELEASE_VERSION,
});

export function setRumUser(id: string, role?: Maybe<UserRole>): void {
  datadogRum.setUser({ id, role });
}

export function clearRumUser(): void {
  datadogRum.clearUser();
}

export function addRumError(
  ...params: Parameters<typeof datadogRum.addError>
): void {
  datadogRum.addError(...params);
}
