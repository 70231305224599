import type React from 'react';
import classNames from 'classnames';
import { Loading } from './loading';

export interface ButtonProps {
  children: React.ReactNode;
  onClick?(): void;
  variant?: 'solid' | 'outline' | 'text';
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'action';
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  hideFocus?: boolean;
  rounded?: boolean;
  type?: 'submit' | 'button';
  className?: string;
}

export const Button = ({
  onClick,
  children,
  variant = 'solid',
  color = 'primary',
  fullWidth = true,
  disabled = false,
  loading = false,
  hideFocus = false,
  rounded = false,
  type = 'button',
  className = '',
}: ButtonProps): JSX.Element => {
  const solidColors = classNames('font-medium', 'border', {
    'bg-primary text-white border-primary hover:bg-primary-darker hover:border-primary-darker':
      !disabled && color === 'primary',
    'bg-white text-primary border-white hover:bg-slate-100 hover:border-slate-100 text-primary':
      !disabled && color === 'secondary',
    'bg-green-600 text-white border-green-600 hover:bg-green-800 hover:border-green-800':
      !disabled && color === 'success',
    'bg-red-600 text-white border-red-600 hover:bg-red-800 hover:border-red-800':
      !disabled && color === 'danger',
    'bg-blue-600 text-white border-blue-600 hover:bg-blue-800 hover:border-blue-800':
      !disabled && color === 'action',
    'bg-green-600 text-white border-green-600 opacity-30':
      disabled && color === 'success',
    'bg-red-600 text-white border-red-600 opacity-30':
      disabled && color === 'danger',
    'bg-primary text-white border-primary opacity-50':
      disabled && color === 'primary',
    'bg-slate-600 text-white border-slate-600':
      disabled && (color === 'action' || color === 'secondary'),
  });

  const outlineColors = classNames(
    {
      'text-slate-700 border-slate-700 hover:bg-slate-700 hover:text-white':
        !disabled && color === 'primary',
      'text-primary border-primary hover:bg-primary-darker hover:text-white':
        !disabled && color === 'secondary',
      'text-green-500 border-green-600 hover:bg-green-600 hover:text-white':
        !disabled && color === 'success',
      'text-red-500 border-red-600 hover:bg-red-600 hover:text-white':
        !disabled && color === 'danger',
      'text-blue-500 border-blue-600 hover:bg-blue-600 hover:text-white':
        !disabled && color === 'action',
      'text-slate-500 hover:text-slate-600 border-slate-600': disabled,
    },
    'bg-transparent',
    'font-medium',
    'border',
  );

  const textColors = classNames(
    {
      'text-slate-900': !disabled && color === 'primary',
      'text-green-500': !disabled && color === 'success',
      'text-red-500': !disabled && color === 'danger',
      'text-blue-500': !disabled && color === 'action',
      'text-slate-500': disabled,
    },
    'font-medium',
  );

  const focusStates = classNames(
    {
      'focus:ring-2 focus:ring-primary focus:ring-opacity-40':
        !disabled && color === 'primary',
      'focus:shadow-success': !disabled && color === 'success',
      'focus:shadow-error': !disabled && color === 'danger',
      'focus:ring-2 focus:ring-blue-600 focus:ring-opacity-40':
        !disabled && color === 'action',
    },
    'focus:outline-none transition-shadow duration-200',
  );

  const styles = classNames(
    'leading-5',
    'py-2.5',
    'px-4',
    'text-sm',
    'rounded',
    { rounded },
    { 'w-full': fullWidth },
    { [focusStates]: !hideFocus },
    { [solidColors]: variant === 'solid' },
    { [outlineColors]: variant === 'outline' },
    { [textColors]: variant === 'text' },
    { 'cursor-not-allowed': disabled },
    className,
  );

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={styles}
    >
      {loading ? (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      ) : (
        children
      )}
    </button>
  );
};
