import type React from 'react';
import { useCallback } from 'react';
import type { FlagValue } from '@eucalyptusvc/react-ff-client';
import { FeatureFlagProvider } from '@eucalyptusvc/react-ff-client';
import { env } from 'env';
import { useNotifications } from './shared/components/notifications';
import { useAuth } from './auth';

export const WrappingFeatureFlagProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const showNotification = useNotifications();
  const { userId, role } = useAuth();

  /**
   * Unimplemented
   * TODO: Implement analytics in doctors-ui
   */
  const emitExposureEvent = useCallback(
    (flagKey: string, flagValue: FlagValue): void => {
      void flagKey;
      void flagValue;
    },
    [],
  );

  const showInitialisationFailed = useCallback(
    (message: string): void => {
      showNotification({ type: 'error', message });
    },
    [showNotification],
  );

  return (
    <FeatureFlagProvider
      userId={userId ?? undefined}
      additionalAttributes={{
        brand: env.brand,
        ...(role && { role }),
      }}
      track={emitExposureEvent}
      showInitialisationFailed={showInitialisationFailed}
      launchDarklyClientID={env.launchDarklyClientId}
    >
      {children}
    </FeatureFlagProvider>
  );
};
