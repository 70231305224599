import React, { useRef } from 'react';
import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloLink,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client';
import { RetryLink } from '@apollo/client/link/retry';
import generatedIntrospection from 'graphql/possible-types';
import { useIntl } from 'react-intl';
import { useNotifications } from './shared/components/notifications';
import { useAuth, tokenNeedsRefreshAtMs, tokenExpiresAtMs } from './auth';
import { logger } from './shared/logging';
import { env } from './env';

const requestLoggerLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-console
  console.log(operation.operationName, operation.variables);
  return forward(operation);
});

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
});

export const ClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>();

  const showNotification = useNotifications();

  const { token, logout, showAuthModal } = useAuth();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  React.useEffect(() => {
    const httpLink = createUploadLink({
      uri: `${env.apiUrl}/graphql`,
    });

    const retryLink = new RetryLink({
      attempts: {
        max: 3,
      },
    });

    const withHeaders = setContext(async (_, { headers }) => {
      if (tokenRef.current) {
        if (tokenExpiresAtMs(tokenRef.current) <= Date.now()) {
          await logout();
          throw new Error('Token Expired');
        }

        if (tokenNeedsRefreshAtMs(tokenRef.current) <= Date.now()) {
          showAuthModal();
        }
      }

      return {
        headers: {
          ...headers,
          brand: env.brand,
          authorization: tokenRef.current ? `Bearer ${tokenRef.current}` : '',
          'x-correlation-id': Math.random(),
        },
      };
    });

    const errorHandler = onError(
      ({ operation, networkError, graphQLErrors }) => {
        if (graphQLErrors) {
          if (
            // This extensions object is actually not guaranteed to be provided.
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHENTICATED')
          ) {
            showNotification({
              type: 'warning',
              message: formatMessage({
                defaultMessage: `Your session has timed out. Please login again.`,
              }),
            });
            logger.warn('Session has timed out.', {
              operation: operation.operationName,
              from: 'apollo',
            });
            void logout();
            return;
          }
          graphQLErrors.forEach(({ message }) => {
            showNotification({
              type: 'error',
              message,
            });
            logger.warn(message, {
              operation: operation.operationName,
              from: 'apollo',
            });
          });
        }

        if (networkError) {
          showNotification({
            type: 'warning',
            message: networkError.message,
          });
          logger.error(networkError.message, {
            operation: operation.operationName,
            from: 'apollo',
          });
        }
      },
    );

    let link = withHeaders;

    if (env.environment !== 'production') {
      link = link.concat(requestLoggerLink);
    }

    link = link.concat(errorHandler).concat(retryLink).concat(httpLink);

    const apolloClient = new ApolloClient({
      cache,
      link,
      defaultOptions: {
        watchQuery: {
          // Make sure we always get latest state from API when
          // a query is used for the first time.
          fetchPolicy: 'cache-and-network',
          // For the remainder of a query's lifecycle we're happy to
          // read already-cached data.
          nextFetchPolicy: 'cache-first',
          // Support partial responses in the case of errors.
          errorPolicy: 'all',
        },
      },
      connectToDevTools: env.environment !== 'production',
    });

    setClient(apolloClient);
  }, [showNotification, logout, showAuthModal, formatMessage]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : (
    <div />
  );
};
