import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'juniper-jp',
  locale: 'ja',
  quizLanguage: 'JA',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  enableLogoutOnInactivity: true,
  diabeteseRiskLevelEnabled: true,
  tracking: {
    enabled: true,
    problemTypes: ['WEIGHT_LOSS'],
  },
  pathologyEnabled: false,
  scriptSigningMethods: [
    ScriptSigningMethod.DIGITAL_SIGNING,
    ScriptSigningMethod.WET_SIGNING,
  ],
  supportsPatientMessaging: true,
  supportsPhotoRequests: [],
  showAdminNotesInPrescribeModal: false,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: false,
  },
  showCallPatientButton: true,
  callProvider: 'LINE',
  prescriptionConfirmationEnabled: true,
  callRequirementDisclaimerEnabled: false,
  dateOfBirthFormat: 'yyyy/MM/dd',
  medicareEnabled: false,
  problemTypes: ['WEIGHT_LOSS'],
  validateShippingAddressWithinRange: false,
  countryCode: 'JP',
};
