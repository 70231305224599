import type React from 'react';
import { forwardRef } from 'react';
import clsx from 'clsx';

/**
 * Please read this before changing any of the tracker card components!
 *
 * This component is intended to mimic the Card design system component so that when we copy compoonents using
 * the DS from customer-frontend, we can change the internal to consume this component.
 * Please don't add more functionality here than whats available in the customer frontend DS.
 */

interface TypographyProps {
  variant: TypographyVariant;
  element?: string;
  className?: string;
}

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'large-paragraph'
  | 'medium-paragraph'
  | 'label'
  | 'paragraph'
  | 'small-text'
  | 'x-small';

const typographyClasses = {
  h1: 'text-4xl font-semibold font-header',
  h2: 'text-3xl font-semibold font-header',
  h3: 'text-xl font-semibold font-header',
  h4: 'text-sm font-semibold font-header',
  h5: 'text-sm font-semibold font-header uppercase',
  h6: 'heading-sm uppercase',
  label: 'text-sm tracking-wider',
  'large-paragraph': 'text-xl font-semibold',
  'medium-paragraph': 'text-base font-semibold',
  paragraph: 'text-base break-words',
  'small-text': 'text-sm',
  'x-small': 'text-xs',
};

const getElementFromVariant = (
  variant: TypographyVariant,
): React.ElementType => {
  switch (variant) {
    case 'large-paragraph':
    case 'medium-paragraph':
    case 'paragraph':
      return 'p';
    case 'small-text':
    case 'x-small':
      return 'span';
    default:
      return variant;
  }
};

type TypographyElement =
  | HTMLParagraphElement
  | HTMLSpanElement
  | HTMLTitleElement
  | HTMLLabelElement;

export const Typography = forwardRef<
  TypographyElement,
  React.PropsWithChildren<TypographyProps>
>(({ children, variant, className, element }, ref) => {
  const Component = element || getElementFromVariant(variant);

  return (
    <Component
      ref={ref}
      className={clsx(typographyClasses[variant], className)}
    >
      {children}
    </Component>
  );
});
Typography.displayName = 'Typography';
