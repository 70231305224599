import React from 'react';
import { env } from 'env';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components/button';
import { Typography } from 'shared/components/ds-components/Typography';
import { logger } from 'shared/logging';
import { addRumError } from 'shared/real-user-monitoring';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    addRumError(error, errorInfo);
    logger.error('Error caught in ErrorBoundary', {
      error: error.toString(),
    });
    if (env.environment !== 'production') {
      // eslint-disable-next-line no-console
      console.error({ error, errorInfo });
    }
  }

  render() {
    if (this.state.error === undefined) {
      return <>{this.props.children}</>;
    }

    return (
      <section className="h-screen flex">
        <div className="max-w-md mx-auto my-auto space-y-8 p-5">
          <div className="space-y-4">
            <Typography
              variant="paragraph"
              className="text-center font-semibold"
            >
              <FormattedMessage
                defaultMessage="Something has gone wrong."
                description="Error screen"
              />
            </Typography>
            <Typography variant="paragraph" className="text-center">
              <FormattedMessage
                defaultMessage="Please try refreshing the page, or should the issue persist contact the engineering team for help."
                description="Error screen"
              />
            </Typography>
          </div>
          <Button onClick={() => window.location.reload()}>
            <FormattedMessage
              defaultMessage="Refresh"
              description="Error screen"
            />
          </Button>
        </div>
      </section>
    );
  }
}
