import type { Environment } from './types';
import { sharedDevEnv, sharedDevEuEnv, sharedJuniperDeEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedJuniperDeEnv,
  ...sharedDevEuEnv,
  adminAppUrl: 'https://admin.dev.de.myjuniper.com',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
};
