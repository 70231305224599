import type React from 'react';
import { FaSpinner } from 'react-icons/fa';
import type { IconBaseProps } from 'react-icons/lib';

export const Loading = (
  props: Omit<IconBaseProps, 'className'> = {},
): React.ReactElement => (
  <FaSpinner {...props} className="spinner stroke-current" />
);

export const CenteredLoading = (): React.ReactElement => (
  <div className="flex w-full justify-center mt-5">
    <Loading />
  </div>
);
