import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'juniper-de',
  locale: 'en-GB',
  quizLanguage: 'EN_GB',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  enableLogoutOnInactivity: true,
  diabeteseRiskLevelEnabled: false,
  tracking: {
    enabled: true,
    problemTypes: ['WEIGHT_LOSS'],
  },

  pathologyEnabled: false,
  scriptSigningMethods: [
    ScriptSigningMethod.DIGITAL_SIGNING,
    ScriptSigningMethod.WET_SIGNING,
  ],
  supportsPatientMessaging: false,
  supportsPhotoRequests: [],
  showAdminNotesInPrescribeModal: true,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: false,
    enableRejectionReasonsForm: true,
  },
  showCallPatientButton: false,
  callRequirementDisclaimerEnabled: false,
  dateOfBirthFormat: 'dd.MM.yyyy',
  medicareEnabled: false,
  problemTypes: ['WEIGHT_LOSS'],
  validateShippingAddressWithinRange: false,
  countryCode: 'DE',
};
