import { useTitle as useTitleLib } from 'react-use';
import { env } from 'env';

export const useTitle = (title?: string): void => {
  let fullTitle = `${env.brandName} Practitioners`;
  if (title) {
    fullTitle = `${title} | ${fullTitle}`;
  }

  useTitleLib(fullTitle, { restoreOnUnmount: true });
};
