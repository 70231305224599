import type { ReactNode } from 'react';
import { createContext, useState, useContext, useEffect } from 'react';

interface FlowActivationContextProps {
  flowActivationQueue: Map<string, FlowActivationData>;
  addToQueue: (patientId: string, activateFlowCallback: () => void) => void;
  removeFromQueue: (patientId: string) => void;
}

interface FlowActivationData {
  activateFlowCallback: () => void;
  timeoutId?: NodeJS.Timeout;
}

const FlowActivationContext = createContext<
  FlowActivationContextProps | undefined
>(undefined);

export const useFlowActivationContext = () => {
  const context = useContext(FlowActivationContext);
  if (!context) {
    throw new Error(
      'useFlowActivationContext must be used within a FlowActivationProvider',
    );
  }
  return context;
};

export const FlowActivationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [flowActivationQueue, setFlowActivationQueue] = useState<
    Map<string, FlowActivationData>
  >(new Map());

  const addToQueue = (patientId: string, activateFlowCallback: () => void) => {
    setFlowActivationQueue((prevQueue) => {
      const newQueue = new Map(prevQueue);
      newQueue.set(patientId, {
        activateFlowCallback,
      });

      let timeoutId: NodeJS.Timeout | undefined;

      if (newQueue.size > 0) {
        timeoutId = setTimeout(() => {
          const patientData = newQueue.get(patientId);
          if (patientData) {
            patientData.activateFlowCallback();
            newQueue.delete(patientId);
            setFlowActivationQueue(newQueue);
          }
        }, 7000);
      }

      newQueue.set(patientId, {
        activateFlowCallback,
        timeoutId,
      });

      return newQueue;
    });
  };

  const removeFromQueue = (patientId: string) => {
    setFlowActivationQueue((prevQueue) => {
      const newQueue = new Map(prevQueue);

      const flowData = newQueue.get(patientId);
      if (flowData && flowData.timeoutId) {
        clearTimeout(flowData.timeoutId);
      }

      newQueue.delete(patientId);
      return newQueue;
    });
  };

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) =>
      event.preventDefault();

    if (flowActivationQueue.size > 0) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [flowActivationQueue]);

  return (
    <FlowActivationContext.Provider
      value={{
        flowActivationQueue,
        addToQueue,
        removeFromQueue,
      }}
    >
      {children}
    </FlowActivationContext.Provider>
  );
};
