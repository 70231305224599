import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'pilot',
  locale: 'en-AU',
  quizLanguage: 'EN_AU',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  pathologyEnabled: false,
  scriptSigningMethods: [ScriptSigningMethod.WET_SIGNING],
  enableLogoutOnInactivity: true,
  supportsPatientMessaging: true,
  supportsPhotoRequests: ['WEIGHT_LOSS', 'HAIR', 'ACNE'],
  showAdminNotesInPrescribeModal: false,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: true,
  },
  tracking: {
    enabled: true,
    problemTypes: ['WEIGHT_LOSS'],
  },
  diabeteseRiskLevelEnabled: true,
  showCallPatientButton: true,
  callProvider: 'ZOOM',
  callRequirementDisclaimerEnabled: true,
  dateOfBirthFormat: 'dd/MM/yyyy',
  medicareEnabled: true,
  problemTypes: [
    'ERECTILE',
    'HAIR',
    'HERPES',
    'MENTAL_HEALTH_MEH',
    'ACNE',
    'MENTAL_HEALTH_SAD',
    'ANTI_AGEING',
    'SLEEP',
    'PREMATURE',
    'WEIGHT_LOSS',
    'COLD_SORES',
    'MENTAL_HEALTH_GOOD',
    'STI',
  ],
  validateShippingAddressWithinRange: true,
  countryCode: 'AU',
};
