import type { Config } from './types';
import { env } from '../env';
import type { Brand } from '../env/types';
import {
  ConfigJuniper,
  ConfigJuniperDe,
  ConfigJuniperJp,
  ConfigJuniperUk,
  ConfigKin,
  ConfigPilot,
  ConfigSoftware,
  ConfigCompoundUk,
} from './brands';

const configByBrand: Record<Brand, Config> = {
  juniper: ConfigJuniper,
  'juniper-de': ConfigJuniperDe,
  'juniper-jp': ConfigJuniperJp,
  'juniper-uk': ConfigJuniperUk,
  kin: ConfigKin,
  pilot: ConfigPilot,
  software: ConfigSoftware,
  'compound-uk': ConfigCompoundUk,
};

/**
 * Use this to access brand specific config variables. Config that is not consistent across environments should live in ENVvars
 */
export const getConfig = (): Config => {
  const brand = env.brand as Brand;
  if (!(brand in configByBrand)) {
    throw new Error(`Config not implemented for brand ${brand}.`);
  }

  return configByBrand[brand];
};
