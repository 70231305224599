import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'kin',
  locale: 'en-AU',
  quizLanguage: 'EN_AU',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  tracking: {
    enabled: false,
  },
  pathologyEnabled: true,
  scriptSigningMethods: [ScriptSigningMethod.WET_SIGNING],
  enableLogoutOnInactivity: true,
  supportsPatientMessaging: true,
  supportsPhotoRequests: ['CONTRACEPTION'],
  showAdminNotesInPrescribeModal: false,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: true,
  },
  diabeteseRiskLevelEnabled: false,
  showCallPatientButton: true,
  callRequirementDisclaimerEnabled: false,
  callProvider: 'ZOOM',
  dateOfBirthFormat: 'dd/MM/yyyy',
  medicareEnabled: true,
  problemTypes: [
    'BREASTFEEDING',
    'CONTRACEPTION',
    'FERTILITY_HORMONE',
    'FERTILITY_NAVIGATOR',
    'FERTILITY_NAVIGATOR_PREGNANCY',
    'POSTPARTUM',
    'POSTPARTUM_MENTAL_HEALTH',
    'POSTPARTUM_NUTRITION',
    'PRECONCEPTION_FERTILITY',
    'PRECONCEPTION_MENTAL_HEALTH',
    'PRECONCEPTION_NUTRITION',
    'PREGNANCY',
    'PREGNANCY_MENTAL_HEALTH',
    'PREGNANCY_NUTRITION',
  ],
  validateShippingAddressWithinRange: false,
  countryCode: 'AU',
};
