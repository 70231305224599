import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'juniper-uk',
  locale: 'en-GB',
  quizLanguage: 'EN_GB',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  enableLogoutOnInactivity: true,
  supportsPatientMessaging: true,
  supportsPhotoRequests: ['WEIGHT_LOSS'],
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: true,
  },
  diabeteseRiskLevelEnabled: false,
  tracking: {
    enabled: true,
    problemTypes: ['WEIGHT_LOSS'],
  },
  pathologyEnabled: false,
  scriptSigningMethods: [
    ScriptSigningMethod.DIGITAL_SIGNING,
    ScriptSigningMethod.WET_SIGNING,
  ],
  showAdminNotesInPrescribeModal: true,
  showCallPatientButton: false,
  callRequirementDisclaimerEnabled: false,
  dateOfBirthFormat: 'dd/MM/yyyy',
  medicareEnabled: false,
  problemTypes: ['WEIGHT_LOSS'],
  validateShippingAddressWithinRange: false,
  countryCode: 'UK',
};
