import type React from 'react';
import { useEffect, useState } from 'react';
import { env } from 'env';
import type { ApolloClient } from '@apollo/client';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import type { ChatProviderQuery, ChatTokenQuery } from 'graphql/types';
import { Loading } from 'shared/components/loading';
import { Chat } from 'stream-chat-react';
import type { Logger } from 'stream-chat';
import { StreamChat } from 'stream-chat';

import { useAuth } from 'auth';
import { logger } from '../shared/logging';

const tokenProvider =
  (apolloClient: ApolloClient<unknown>, isLoggedIn: () => boolean) =>
  async () => {
    if (!isLoggedIn()) {
      throw new Error('not logged in, cannot fetch token');
    }
    const resp = await apolloClient.query<ChatTokenQuery>({
      query: gql`
        query ChatToken {
          chatToken
        }
      `,
      fetchPolicy: 'network-only',
    });
    if (!resp.data.chatToken) {
      throw new Error('chat token could not be retrieved');
    }
    return resp.data.chatToken;
  };

export const ChatProvider: React.FC = ({ children }) => {
  const { role, loggedIn } = useAuth();
  const apolloClient = useApolloClient();
  const { data, loading } = useQuery<ChatProviderQuery>(
    gql`
      query ChatProvider {
        profile {
          id
        }
      }
    `,
    {
      skip: role !== 'HEALTH_COACH' && role !== 'DOCTOR',
    },
  );

  const [client, setClient] = useState<StreamChat>();

  useEffect(() => {
    const key = env.streamAppKey;

    if (!key || !data?.profile?.id) {
      return undefined;
    }

    const streamLogger: Logger = (logLevel, message, extraData) => {
      if (logLevel !== 'error' && logLevel !== 'warn') {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- extra data is just an object that these libs type differently
      // @ts-ignore
      logger.log(message, extraData, logLevel);
    };
    const newClient = new StreamChat(key, {
      logger: streamLogger,
      timeout: 10000,
    });

    const handleConnectionChange = ({ online = false }) => {
      if (!online) return logger.info('connection lost');
      return setClient(newClient);
    };

    newClient.on('connection.changed', handleConnectionChange);

    void newClient.connectUser(
      {
        id: data.profile.id,
      },
      tokenProvider(apolloClient, () => loggedIn),
    );

    setClient(newClient);

    return () => {
      void newClient.off('connection.changed', handleConnectionChange);
      void newClient
        .disconnectUser()
        .then(() => logger.info('connection closed'));
    };
  }, [apolloClient, data?.profile?.id, loggedIn]);

  if (loading) {
    return <Loading />;
  }

  if (!client) {
    return <>{children}</>;
  }

  return <Chat client={client}>{children}</Chat>;
};
