import type { Brand } from 'env/types';
import type { ProblemType, SupportedLanguages } from 'graphql/types';

export interface Config {
  mimsLogoUrl: string;
  mimsUrl: string;
  tracking: {
    enabled: boolean;
    problemTypes?: ProblemType[];
  };
  pathologyEnabled: boolean;
  scriptSigningMethods: ScriptSigningMethod[];
  enableLogoutOnInactivity: boolean;
  supportsPatientMessaging: boolean;
  supportsPhotoRequests: ProblemType[];
  showAdminNotesInPrescribeModal: boolean;
  diabeteseRiskLevelEnabled: boolean;
  locale: ConfigLocale;
  dateOfBirthFormat: string;
  brand: Brand;
  showCallPatientButton: boolean;
  callProvider?: CallProvider;
  prescriptionConfirmationEnabled?: boolean;
  callRequirementDisclaimerEnabled: boolean;
  quizLanguage: SupportedLanguages;
  medicareEnabled: boolean;
  rejection: {
    enableRejectionReasonPlaceholderInLetter: boolean;
    enableRejectionReasonsForm: boolean;
  };
  validateShippingAddressWithinRange: boolean;
  countryCode: string;
  problemTypes: Array<ProblemType>;
}

export enum ScriptSigningMethod {
  WET_SIGNING = 'WET_SIGNING', // Doctor has to print the scripts and physically sign it
  DIGITAL_SIGNING = 'DIGITAL_SIGNING', // Digital signing - as of 16/Jun/22 only Juniper UK can utilise this
}

type ConfigLocale = 'en-AU' | 'de' | 'ja' | 'en-GB';

export type CallProvider = 'ZOOM' | 'LINE';
