import type {
  ChatThreadStatus,
  FilteredChatThreadsInput,
  ChatThreadOrderBy,
} from 'graphql/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CHAT_THREAD_STATUSES } from './utils';

export const useFilteredChatThreadsSearchParams =
  (): FilteredChatThreadsInput => {
    const { search } = useLocation();
    return useMemo<FilteredChatThreadsInput>(() => {
      const searchParams = new URLSearchParams(search);
      const pageToken = searchParams.get('pageToken');
      const searchTerm = searchParams.get('searchTerm');
      const orderByQueryParam = searchParams.get('orderBy');
      const primaryUserIds = searchParams.getAll('primaryUserIds');
      const chatThreadStatuses = searchParams
        .getAll('chatThreadStatuses')
        .filter((s): s is ChatThreadStatus =>
          CHAT_THREAD_STATUSES.includes(s as ChatThreadStatus),
        );
      const hasPrimaryUserString = searchParams.get('hasPrimaryUser');
      let hasPrimaryUser: boolean | undefined;
      if (hasPrimaryUserString === 'true') {
        hasPrimaryUser = true;
      } else if (hasPrimaryUserString === 'false') {
        hasPrimaryUser = false;
      }

      let orderBy: ChatThreadOrderBy | undefined;
      switch (orderByQueryParam) {
        case 'PRIORITY_DESC':
          orderBy = 'PRIORITY_DESC';
          break;
        case 'UPDATED_AT_ASC':
          orderBy = 'UPDATED_AT_ASC';
          break;
        case 'UPDATED_AT_DESC':
          orderBy = 'UPDATED_AT_DESC';
          break;
        default:
        // noop
      }

      return {
        pageRequest: {
          pageSize: 7,
          pageToken: pageToken ?? undefined,
        },
        searchTerm: searchTerm ?? undefined,
        primaryUserIds: primaryUserIds.length ? primaryUserIds : undefined,
        chatThreadStatuses: chatThreadStatuses.length
          ? chatThreadStatuses
          : undefined,
        hasPrimaryUser,
        orderBy,
        chatThreadTypes: ['HEALTH_COACH'],
      };
    }, [search]);
  };
