import type { PropsWithChildren } from 'react';
import type { TooltipProps } from './tool-tip';
import { Tooltip } from './tool-tip';

interface Props {
  htmlFor?: string;
  tooltip?: TooltipProps;
}

export const Label = ({
  children,
  htmlFor,
  tooltip,
}: PropsWithChildren<Props>): JSX.Element => (
  <>
    {children && (
      <label htmlFor={htmlFor} className="heading-sm block">
        {children}
        {tooltip && <Tooltip {...tooltip} />}
      </label>
    )}
  </>
);
