import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'software',
  locale: 'en-AU',
  quizLanguage: 'EN_AU',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  pathologyEnabled: true,
  scriptSigningMethods: [ScriptSigningMethod.WET_SIGNING],
  enableLogoutOnInactivity: true,
  supportsPatientMessaging: true,
  supportsPhotoRequests: ['HAIR', 'SKIN_GENERAL', 'ANTI_AGEING', 'ACNE'],
  showAdminNotesInPrescribeModal: false,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: true,
  },
  diabeteseRiskLevelEnabled: false,
  tracking: {
    enabled: true,
    problemTypes: ['SKIN_GENERAL', 'ACNE', 'ANTI_AGEING', 'HAIR'],
  },
  showCallPatientButton: true,
  callRequirementDisclaimerEnabled: false,
  callProvider: 'ZOOM',
  dateOfBirthFormat: 'dd/MM/yyyy',
  medicareEnabled: true,
  problemTypes: ['ACNE', 'ANTI_AGEING', 'HAIR', 'STI', 'SKIN_GENERAL'],
  validateShippingAddressWithinRange: false,
  countryCode: 'AU',
};
