import type { FC } from 'react';
import type React from 'react';
import className from 'classnames';
import type { Maybe } from 'graphql/types';

interface UserAvatarProps {
  user: {
    id: string;
    fullName?: Maybe<string>;
    avatar?: Maybe<{ url: string }>;
  };
  size?: 'small' | 'medium' | 'large';
}

const avatarBgColors = [
  'bg-slate-500',
  'bg-red-500',
  'bg-orange-500',
  'bg-yellow-500',
  'bg-green-500',
  'bg-teal-500',
  'bg-blue-500',
  'bg-indigo-500',
  'bg-purple-500',
  'bg-pink-500',
];

// Hash a string to a number
// Used so that there is a consistent bg color on a user's avatar
const simpleStringHash = (str: string): number =>
  str.split('').reduce((a, c) => a + c.charCodeAt(0), 0);

const getAvatarBgColor = (id: string): string =>
  avatarBgColors[simpleStringHash(id) % avatarBgColors.length] ?? '';

const getInitials = (patientName: string): string =>
  patientName
    .split(' ')
    .map((n) => n[0])
    .join('');

type AvatarProps = {
  name?: string | null;
  patientId: string;
  avatarUrl: string | null | undefined;
  size?: 'xs' | 'small' | 'medium' | 'large';
};

export const Avatar: FC<AvatarProps> = ({
  name,
  patientId,
  avatarUrl,
  size = 'medium',
}) => {
  const initials = name ? getInitials(name) : '?';

  const bubbleClass = className(
    getAvatarBgColor(patientId),
    'rounded-full flex overflow-hidden',
    { 'items-center justify-center': !avatarUrl },
    { 'h-6 w-6': size === 'xs' },
    { 'h-8 w-8': size === 'small' },
    { 'h-10 w-10': size === 'medium' },
    { 'h-16 w-16': size === 'large' },
  );

  const initialsClass = className(
    'text-white uppercase',
    { 'text-xs': size === 'xs' },
    { 'text-sm': size === 'small' },
    { 'text-md': size === 'medium' },
    { 'text-2xl': size === 'large' },
  );

  return (
    <div className={bubbleClass}>
      {avatarUrl ? (
        <img className="object-cover" src={avatarUrl} />
      ) : (
        <span className={initialsClass}>{initials}</span>
      )}
    </div>
  );
};

export const UserAvatar = ({
  user,
  size = 'medium',
}: UserAvatarProps): React.ReactElement => (
  <Avatar
    name={user.fullName ?? ''}
    patientId={user.id}
    avatarUrl={user.avatar?.url}
    size={size}
  />
);
