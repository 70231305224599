import { logger } from 'shared/logging';
import { throttle } from 'throttle-typescript';

const EXPIRED_AT_KEY = 'expires_at';

export class InactiveTimer {
  private interval: number | undefined;

  private timeoutSeconds: number;

  private onExpire?: () => void;

  private eventHandler: () => void;

  constructor({ timeoutSeconds }: { timeoutSeconds: number }) {
    this.timeoutSeconds = timeoutSeconds;
    this.eventHandler = this.refreshLastActiveTime.bind(this);
    this.attachListeners();
    logger.info(`Adding timer with time of: ${timeoutSeconds}`);
  }

  refreshLastActiveTime(): void {
    localStorage.setItem(
      EXPIRED_AT_KEY,
      `${Date.now() + this.timeoutSeconds * 1000}`,
    );
  }

  setOnExpire(fnc: () => void): void {
    this.onExpire = fnc;
  }

  start(): void {
    if (typeof this.onExpire !== 'function') {
      throw new Error('Required field onExpire not set');
    }
    logger.info(
      `starting inactivity timer with timeout: ${this.timeoutSeconds}`,
    );

    this.refreshLastActiveTime();
    this.interval = window.setInterval(() => {
      const expiresAt = parseInt(
        localStorage.getItem(EXPIRED_AT_KEY) || '0',
        10,
      );

      logger.info(`time left to log out ${(expiresAt - Date.now()) / 1000}`);

      if (expiresAt < Date.now()) {
        this.onExpire?.();
        this.cleanUp();
      }
    }, 30000);
  }

  attachListeners(): void {
    window.addEventListener('mousemove', throttle(this.eventHandler, 30));
    window.addEventListener('scroll', throttle(this.eventHandler, 30));
    window.addEventListener('keydown', throttle(this.eventHandler, 30));
  }

  cleanUp(): void {
    logger.info(
      `cleaning up inactivity timer with timeout: ${this.timeoutSeconds}`,
    );
    localStorage.removeItem(EXPIRED_AT_KEY);
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}
